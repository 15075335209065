export const Route_Url = {
  // Client
  LandingPage: "/home",
  Login: "/login",
  SignUp: "/signup",
  payment: "/payment",
  paypalPayment: "/payment/paypal",
  Checkout: "/payment/checkout",
  Complete: "/completePayment",
  PaymentOnboarding: "/payments/onboarding",
  EmailVerificaiton: "/linksent",
  Client: "/client",
  AppointmentBooking: "/client/appointmentbooking",
  AppointmentComplete: "/client/appointmentbookingcomplete",
  AppointmentBookingComplete: "/client/appointmentbookingcomplete",
    PatientDashboard: "/client/dashboard",
  Questionnaire: "/client/questions/:appointmentId",
  EvidenceSubmission: "/client/evidencesubmission/:appointmentId",
  CancelAppointmentBooking: "/client/cancelBooking/:appointmentId",
  RequestReport: "/client/requestreport",
  ReportRequestComplete: "/client/reportRequestComplete",
  CancelAppointmentBookingComplete: "/client/cancelBooking/complete",
  FeedbackSurveyComplete: "/client/feedbackSurveyComplete",
  
  // Admin
  Admin: "/admin",
  Users: "/admin/users",
  AdminDashboard: "/admin/dashboard",
  // Doctor
  Therapist: "/therapist",
  DoctorDashboard: "/therapist/dashboard",
  AppointmentList: "/therapist/appointmentlist",
  EditAppointment: "/therapist/editappointment/:appointmentId",
  ViewAppointment: "/therapist/editappointment",
  TherapistSettings: "/therapist/settings",

  //Login Callback with Google
  GoogleLoginCallback : "/google-login-callback",

  //Login Callback with outlook
  OutlookLoginCallback : "/outlook-login-callback",

  // Survey
  Survey: "/client/:appointmentId/survey",

  OfficeManagerRoot: "/office-manager",
  OfficeManager: {
    PendingReviewReport: "/office-manager/pending-review",
    PendingPrequisities: "/office-manager/pending-prerequisites",
    DelayedPayments: "/office-manager/delayed-payments",
    ReportRequests: "/office-manager/report-requests",
    Settings: "/office-manager/settings",
    PendingClearingHouse: "/office-manager/pending-clearinghouse",
  }
};
