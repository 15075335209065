import { useQuery } from "@tanstack/react-query";
import { getTherapistQuestionTemplate } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchTherapistQuestionTemplate  = (language) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_THERAPIST_QUESTION_TEMPLATE,language],
    queryFn: () =>
      getTherapistQuestionTemplate(language),
    enabled: !!language,
    cacheTime: 0,
    
  });
