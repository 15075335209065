import { getCalendarBookings } from "services/ApiService";
import { calendarActions, progressActions } from "../slicers";

export const loadCalendarEvents = (therapistId, isFollowUp) => {
  return async (dispatch) => {
    dispatch(progressActions.increment());
    try {
      const response = await getCalendarBookings(therapistId, isFollowUp);
      dispatch(calendarActions.setData(response));
      dispatch(progressActions.decrement());
    } catch (error) {
      dispatch(progressActions.decrement());
    }
  };
};

export const addCalendarEvent = (event) => {
  return (dispatch) => {
    dispatch(calendarActions.addEvent(event));
  };
};
