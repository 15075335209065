import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import PropTypes from "prop-types";
import { COLORS } from "common/constants";
import { useSelector } from "react-redux";
import { getUser } from "store/selectors";
import { isClient, isClientRoute, isTherapist, isTherapistRoute } from "helper";
export default function AntDesignProvider(props) {
  const user = useSelector(getUser);
  const pathname = window.location.pathname;
  const loggedInAsClient =
    (user && Object.keys(user).length == 0) ||
    isClient(user) ||
    isClientRoute(pathname);
  const logoutCondition =
    isTherapistRoute(pathname) && user && Object.keys(user).length == 0;
  const loginAndSignup = /login|\/signup|\/home|\/|\/completePayment/i.test(pathname);
  
  useEffect(() => {
    if (loggedInAsClient && !loginAndSignup) {
      document.documentElement.style.setProperty(
        "--color-primary-dark",
        COLORS.SECONDARY.dark
      );
      document.documentElement.style.setProperty(
        "--color-secondary-dark",
        COLORS.PRIMARY.dark
      );
    } else {
      document.documentElement.style.setProperty(
        "--color-primary-dark",
        COLORS.PRIMARY.dark
      );
      document.documentElement.style.setProperty(
        "--color-secondary-dark",
        COLORS.SECONDARY.dark
      );
    }
  }, [loggedInAsClient, loginAndSignup,pathname]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: loggedInAsClient
            ? COLORS.SECONDARY.dark
            : COLORS.PRIMARY.dark,
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  );
}

AntDesignProvider.propTypes = {
  children: PropTypes.node,
};
