export const TooltipMessages = {
  SIGN_UP: {
    SAP_NAME: "This is the name used on email signatures",
    CLIENT_CONTACT_EMAIL:
      "Set the email address clients should use to contact your practice. This email will be displayed in client communications",
    FMSCA_CLEARING_HOUSE:
      "This is the name that clients will be instructed to assign in the FMSCA Clearinghouse, if applicable",
    RECORD_DISPLAY: "This will be displayed next to your name on reports",
  },
  PROFILE: {
    ABOUT: "This is displayed on the client facing appointment booking pages.",
    SYNC_CALENDAR:
      "If you allow calendar to sync, bookings will avoid conflicting with events already on your calendar",
    SIGNATURE:
      "Upload a picture of your signature. Once uploaded, it will automatically appear on the Initial and Follow-Up Evaluation reports",
    LOGO: "Upload a picture of your logo. Once uploaded, it will automatically appear on the Initial and Follow-Up Evaluation reports.",
  },
  APPOINTMENT: {
    ADDITIONAL_REPORT_CHARGES:
      "SAP Flow allows for one complimentary additional report per client. If the client requests more than one additional report, the corresponding fee will be applied to their account.",
    CONFIRMATION_DEPOSIT:
      "This amount serves as a deposit that must be paid by the client to secure their initial evaluation appointment and will be applied toward the total charge.",
    PAYMENT_IN_INSTALLMENTS:
      "This setting allows SAPs to choose the number of installments (1, 2, 3, or 4) they are willing to accept for SAP Evaluation payments. The selected option determines how the total charge is divided for clients",
    CHARGES_PER_APPOINTMENT:
      "This is the total amount the client will be charged for both the Initial Evaluation and the Follow-Up Evaluation.",
    AVAILABILITY:
      "When marked unavailable, clients will not be able to book evaluations on your calendar & your calendar will display that you are not currently accepting new clients",
    START_END_TIME:
      "Your available days, start times, and end times determine when clients can book evaluations. If you sync your Google or Outlook calendar in Personal Info settings, the system will automatically prevent bookings that conflict with your existing events",
    INSTRUCTIONS:
      "These instructions will be displayed on the booking page for the Initial Evaluation. Add any instructions for the client.",
    COUNSELORS_TO_REFER:
      "Configure a list of counselors for client referrals when counseling is prescribed in a treatment plan. Additionally, add an educational  course platform and relevant educational courses for clients as part of their treatment plan. This list will be available when creating treatment plans for each client",
    GRACE_PERIOD:
      "Set a buffer time after each appointment before the next booking can be scheduled. This ensures you have time for notes, breaks, or unexpected delays before your next session",
  },
  OFFICE_MANAGER: {
    MANAGER_TABLE_VISIBILITY:
      "Customize which client management tables and notifications are enabled for each office manager. Their dashboard view and alerts are independent of your settings above.",
    PERSONAL_TABLE_VISIBILITY:
      "Customize which client management tables appear on your dashboard and enable notifications for each. Toggle each table on or off to control visibility and receive relevant updates. Office managers have separate settings below",
  },
  EDIT_APPOINTMENT: {
    STATUS: `1. On-Time: The appointment is confirmed to proceed at the scheduled time.
    2. Rescheduled: A reschedule request has been sent to the client, but they have not selected a new time yet.
    3. Canceled: The appointment has been canceled and will not take place.`,
    INTAKE_FORM: `1. Pending: The client has not yet completed the intake form.
    2. Completed: The client has completed their intake form`,
  },
};
