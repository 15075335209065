import { store } from "store";
import { loadUser } from "store/action-creators";
import { notificationActions, progressActions, userActions } from "store/slicers";

export const getStoreDispatch = store.dispatch;

export const dispatchNotification = (type, message, description = "", placement) =>
  store.dispatch(
    notificationActions.addNotification({
      type,
      message,
      description,
      placement
    })
  );

export const dispatchLoadUser = () => {
  store.dispatch(loadUser());
};

export const showLoader = () => {
  store.dispatch(progressActions.increment());
};

export const hideLoader = () => {
  store.dispatch(progressActions.decrement());
};

export const getUserFromState = () => store.getState().user;
export const getLangFromState = () => store.getState().lang?.value;
