import { Spin, Typography } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
export default function DynamicLoadingIndicator(props) {
  const loadingText = (
    <Typography.Title level={4} editable={false} className="pt-3 text-secondary-dark text-center">
      { props.text}
    </Typography.Title>
  );

  return (
    <Spin
      size="large"
      delay={500}
      fullscreen
      spinning
      tip={loadingText}
      className="cursor-pointer"
      indicator={<LoadingOutlined className="text-primary-dark" style={{ fontSize: 48 }} spin />}
    >
    </Spin>
  );
}

