import { notification } from "antd";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { useActions } from "common/hooks";

export default function NotificationProvider(props) {
  const { type, message, description, placement } = useSelector(
    (state) => state.notification,
  );
  const { clearNotification } = useActions();
  const [api, contextHolder] = notification.useNotification({
    placement,
    duration: 1000,
  });

  const timeoutRef = useRef();

  const showToast = React.useCallback(() => {
    api.open({
      message,
      description,
      type,
      key: message,
      placement,
    });
    
    timeoutRef.current = setTimeout(() => {
      api.destroy(message);
      clearNotification();
    }, 4000);
  }, [api, message, description, type, placement, clearNotification]);

  useEffect(() => {
    if (message) showToast();
    return () => clearTimeout(timeoutRef.current);
  }, [message, showToast]);
  return (
    <React.Fragment>
      {contextHolder}
      {props.children}
    </React.Fragment>
  );
}


NotificationProvider.propTypes = {
  children: PropTypes.node
}
