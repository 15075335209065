import { dashboard } from "components/imagepath";
import { first, last } from "lodash";
import { bookAppointment } from "services/ApiService";

const TRANSALATION_TEXT = {
  en: {
    choose_your_appointent_time: {
      key: "choose_your_appointent_time",
      value: "Add Appointment",
    },
    login_text: {
      key: "login_text",
      value: "Login",
    },
    calendarOptions: {
      monthly: {
        key: "monthly",
        value: "Monthly",
      },
      weekly: {
        key: "weekly",
        value: "Weekly",
      },
      "today:": {
        key: "today",
        value: "Today",
      },
    },
    previous: {
      key: "previous",
      value: "Previous",
    },
    next: {
      key: "next",
      value: "Next",
    },
    bookAppointment: {
      key: "bookAppointment",
      value: "Book Appointment",
    },
    reset: {
      key: "reset",
      value: "Reset",
    },
    language: {
      key: "language",
      value: "Cambiar al español",
    },
    navigation: {
      dashboard: {
        key: "dashboard",
        value: "Dashboard",
      },
      appointmentList: {
        key: "appointmentList",
        value: "Appointment List",
      },
      settings: {
        key: "settings",
        value: "Settings",
      },
      appointmentBooking: {
        key: "appointmentBooking",
        value: "Appointment Booking",
      },
      users: {
        key: "users",
        value: "Users",
      },
    },
    therapistDashboard: {
      appointmentsThisMonth: {
        key: "appointmentsThisMonth",
        value: "Appointments this month",
      },

      appointmentsLeftToday: {
        key: "appointmentsLeftToday",
        value: "Appointments left today",
      },
      clientsThisMonth: {
        key: "clientsThisMonth",
        value: "Clients this month",
      },
      OutstandingBalance: {
        key: "TotalOutstandingBalance",
        value: "Total Outstanding Balance",
      },
      TodaysAppointments: {
        key: "TodaysAppointments",
        value: "Today's Appointments",
      },
      NextAppointmentIn: {
        key: "NextAppointmentIn",
        value: "Next Appointment in",
      },
      TotalEarnings: {
        key: "TotalEarnings",
        value: "Total Earnings",
      },
      TotalClients: {
        key: "TotalClients",
        value: "Total Clients",
      },
      HaveANiceDayAtWork: {
        key: "HaveANiceDayAtWork",
        value: "Have a nice day at work",
      },
    },
    statuses: {
      pending: {
        key: "pending",
        value: "Pending",
      },
      completed: {
        key: "completed",
        value: "Completed",
      },
      onTime: {
        key: "OnTime",
        value: "On-Time",
      },
      cancelled: {
        key: "cancelled",
        value: "Cancelled",
      },
      followUp: {
        key: "followUp",
        value: "Follow-Up",
      },
      rescheduled: {
        key: "rescheduled",
        value: "Rescheduled",
      },
    },
    initialAssessement: {
      rules: {
        completeTheFollowingStepsBy8AmOnYouApptDay: {
          key: "completeTheFollowingStepsBy8AmOnYouApptDay",
          value: "Complete the following steps by 8 am on your Appt day",
        },
        beforeYourAppointment: {
          key: "beforeYourAppointment",
          value: "Before your appointment",
        },
        payANonRefundableDeposit: {
          key: "payANonRefundableDeposit",
          value: "Pay a non-refundable",
        },
        depositHere: {
          key: "depositHere",
          value: "deposit here",
        },
        includeYourFirstAndLastNameInThePaymentNotes: {
          key: "includeYourFirstAndLastNameInThePaymentNotes",
          value: "Include your first and last names in the payment notes",
        },
        register: {
          key: "register",
          value: "Register",
        },
        asYourSAPInTheClearinghouseIfApplicable: {
          key: "asYourSAPInTheClearinghouseIfApplicable",
          value:
            "as your SAP in the Clearinghouse, if applicable For help call 844-955-0207",
        },
        fillOutTheNewClientFormAndAssessments: {
          key: "fillOutTheNewClientFormAndAssessments",
          value:
            "Fill out the new client form and assessments from the appointment confirmation mail you receive after booking an appointment",
        },
        theseStepsMustBeCompletedToAvoidAutomaticRescheduling: {
          key: "theseStepsMustBeCompletedToAvoidAutomaticRescheduling",
          value:
            "These steps must be completed to avoid automatic rescheduling",
        },
        minimumIsNeededToSecureYourBooking: {
          key: "minimumIsNeededToSecureYourBooking",
          value:
            "minimum is needed to secure your booking;full payment is accepted",
        },
        Give48HoursNoticeToCancelOrReschedule: {
          key: "Give48HoursNoticeToCancelOrReschedule",
          value:
            "Give a 48-hour rescheduling notice. Rescheduling within 24 hours incurs a",
        },
        fee: {
          key: "fee",
          value: "fee",
        },
        SimplyCancelRebook: {
          key: "SimplyCancelRebook",
          value: "Simply Cancel & Rebook",
        },
        noPreliminaryInformationCallIsNeededForBooking: {
          key: "noPreliminaryInformationCallIsNeededForBooking",
          value:
            "No preliminary information call is needed for booking. For assistance, email",
        },
        contactSapflowAi: {
          key: "contactSapflowAi",
          value: "contact@sapflow.ai",
        },
      },
    },
    followUpAppointment: {
      content1: {
        key: "content1",
        value: `The SAP follow-up evaluation may only be booked once the
                    prescribed treatment plan is completed. If you book a follow
                    up evaluation with incomplete treatment plan items, you will
                    be charged a`,
      },
      content2: {
        key: "content2",
        value: `If you reschedule within 24 hours of your appointment, you
                    will be charged a`,
      },
      content3: {
        key: "content3",
        value: `If you have an outstanding balance on your account, you must
                    pay your remaining balance at least 2 hours before your
                    Follow-up Evaluation.`,
      },
      content4: {
        key: "content4",
        value: `If the remaining balance is not
                    paid 2 hours before your appointment, the appointment will
                    be automatically canceled, and will be subject to a`,
      },
      content5: {
        key: "content5",
        value: `To reschedule, please cancel the appointment & book another appointment.`,
      },
      title: {
        key: "title",
        value: "Follow-Up Appointment",
      },
      lateRescheduleFee: {
        key: "lateRescheduleFee",
        value: "late reschedule fee.",
      }
    },
    appointmentBooking: {
      appointmentDate: {
        key: "appointmentDate",
        value: "Appointment Date",
      },
      appointmentTime: {
        key: "appointmentTime",
        value: "Appointment Time",
      },
      duration: {
        key: "duration",
        value: "Duration",
      },
      minutes: {
        key: "minutes",
        value: "minutes",
      },
      appointmentDetails: {
        key: "appointmentDetails",
        value: "Appointment Details",
      },
      personalDetails: {
        key: "personalDetails",
        value: "Personal Details",
      },
      days: {
        Monday: {
          key: "Monday",
          value: "Monday",
        },
        Tuesday: {
          key: "Tuesday",
          value: "Tuesday",
        },
        Wednesday: {
          key: "Wednesday",
          value: "Wednesday",
        },
        Thursday: {
          key: "Thursday",
          value: "Thursday",
        },
        Friday: {
          key: "Friday",
          value: "Friday",
        },
        Saturday: {
          key: "Saturday",
          value: "Saturday",
        },
        Sunday: {
          key: "Sunday",
          value: "Sunday",
        },
      },
    },
    formFields: {
      firstName: {
        key: "firstName",
        value: "First Name",
      },
      lastName: {
        key: "lastName",
        value: "Last Name",
      },
      email: {
        key: "email",
        value: "Email",
      },
      mobileNumber: {
        key: "mobileNumber",
        value: "Mobile Number",
      },
      address: {
        key: "address",
        value: "Address",
      },
      dateOfBirth: {
        key: "dateOfBirth",
        value: "Date of Birth (MM/DD/YYYY)",
      },
      gender: {
        key: "gender",
        value: "Gender",
      },
      last4DigitsOfSSN: {
        key: "last4DigitsOfSSN",
        value: "Last 4 digits of SSN",
      },
      bookandConfirm: {
        key: "bookandConfirm",
        value: "Book & Confirm",
      },
      cancel: {
        key: "cancel",
        value: "Cancel",
      },
      male: {
        key: "male",
        value: "Male",
      },
      female: {
        key: "female",
        value: "Female",
      },
      other: {
        key: "others",
        value: "Others",
      },
      required_message: {
        key: "required_message",
        value: "Answer is required",
      },
      emptySpaces: {
        key: "emptySpaces",
        value: "Empty spaces are not allowed",
      },
      nextSection: {
        key: "nextSection",
        value: "Next Section",
      },
    },
    clientDashboard: {
      welcomeText: {
        key: "welcome_back",
        value: "Welcome back",
      },
      noAppointments: {
        key: "no_appointments",
        value: "No appointment found",
      },
      currentStage: {
        key: "current_stage",
        value: "Current Stage",
      },
      remainingEvidence: {
        key: "remaining_evidence",
        value: "Remaining Evidences",
      },
      outstandingBalance: {
        key: "outstanding_balance",
        value: "Outstanding Balance",
      },
      nextAppointmentDate: {
        key: "nextAppointmentDate",
        value: "Next Appointment Date",
      },
    },
    clientAppointmentBooking: {
      notAvailableCurrently: {
        key: "notAvailableCurrently",
        value: "Not available currently",
      },
      therapistUnavailableMessage: {
        key: "therapistUnavailableMessage",
        value: "Therapist that you are trying to book is not available currently. Please try again later.",
      },
    },
  },
  es: {
    choose_your_appointent_time: {
      key: "choose_your_appointent_time",
      value: "Elige tu hora de cita",
    },
    login_text: {
      key: "login_text",
      value: "Iniciar sesión",
    },
    calendarOptions: {
      monthly: {
        key: "monthly",
        value: "Mensual",
      },
      weekly: {
        key: "weekly",
        value: "Semanal",
      },
      "today:": {
        key: "today",
        value: "Hoy",
      },
    },
    previous: {
      key: "previous",
      value: "Anterior",
    },
    next: {
      key: "next",
      value: "Siguiente",
    },
    bookAppointment: {
      key: "bookAppointment",
      value: "Reservar cita",
    },
    reset: {
      key: "reset",
      value: "Reiniciar",
    },
    language: {
      key: "language",
      value: "Switch to English",
    },
    navigation: {
      dashboard: {
        key: "dashboard",
        value: "Tablero",
      },
      appointmentList: {
        key: "appointmentList",
        value: "Lista de citas",
      },
      settings: {
        key: "settings",
        value: "Configuraciones",
      },
      appointmentBooking: {
        key: "appointmentBooking",
        value: "Reserva de citas",
      },
      users: {
        key: "users",
        value: "Usuarios",
      },
    },
    therapistDashboard: {
      appointmentsThisMonth: {
        key: "appointmentsThisMonth",
        value: "Citas este mes",
      },
      appointmentsLeftToday: {
        key: "appointmentsLeftToday",
        value: "Citas restantes hoy",
      },
      clientsThisMonth: {
        key: "clientsThisMonth",
        value: "Clientes este mes",
      },
      OutstandingBalance: {
        key: "TotalOutstandingBalance",
        value: "Ganancias este mes",
      },
      TodaysAppointments: {
        key: "TodaysAppointments",
        value: "Citas de hoy",
      },
      NextAppointmentIn: {
        key: "NextAppointmentIn",
        value: "Próxima cita en",
      },
      TotalEarnings: {
        key: "TotalEarnings",
        value: "Ganancias totales",
      },
      TotalClients: {
        key: "TotalClients",
        value: "Clientes totales",
      },
      HaveANiceDayAtWork: {
        key: "HaveANiceDayAtWork",
        value: "Que tengas un buen día de trabajo",
      },
    },
    statuses: {
      pending: {
        key: "pending",
        value: "Pendiente",
      },
      completed: {
        key: "completed",
        value: "Completado",
      },
      onTime: {
        key: "OnTime",
        value: "A tiempo",
      },
      cancelled: {
        key: "cancelled",
        value: "Cancelado",
      },
      followUp: {
        key: "followUp",
        value: "Seguimiento",
      },
      rescheduled: {
        key: "rescheduled",
        value: "Reprogramado",
      },
    },
    initialAssessement: {
      rules: {
        completeTheFollowingStepsBy8AmOnYouApptDay: {
          key: "completeTheFollowingStepsBy8AmOnYouApptDay",
          value: "DEBE COMPLETAR LOS SIGUIENTES PASOS ANTES DE LAS 8 a.m.",
        },
        beforeYourAppointment: {
          key: "beforeYourAppointment",
          value: "DEL DÍA DE SU CITA.",
        },
        payANonRefundableDeposit: {
          key: "payANonRefundableDeposit",
          value: "Paga un depósito de",
        },
        depositHere: {
          key: "depositHere",
          value: "aquí.",
        },
        includeYourFirstAndLastNameInThePaymentNotes: {
          key: "includeYourFirstAndLastNameInThePaymentNotes",
          value: "Incluye tus nombres y apellidos en las notas del pago.",
        },
        register: {
          key: "register",
          value: "Registra",
        },
        asYourSAPInTheClearinghouseIfApplicable: {
          key: "asYourSAPInTheClearinghouseIfApplicable",
          value:
            "como tu SAP en el Clearinghouse de FMSCA, si aplica. Si necessitas ayuda llame 844-955-0207",
        },
        fillOutTheNewClientFormAndAssessments: {
          key: "fillOutTheNewClientFormAndAssessments",
          value:
            "Completa el formulario para nuevos clientes y las evaluaciones aquí.",
        },
        theseStepsMustBeCompletedToAvoidAutomaticRescheduling: {
          key: "theseStepsMustBeCompletedToAvoidAutomaticRescheduling",
          value:
            "Complete estos pasos antes para evitar la reprogramación automática.",
        },
        minimumIsNeededToSecureYourBooking: {
          key: "minimumIsNeededToSecureYourBooking",
          value:
            "se necesita un mínimo para asegurar su reserva; se acepta el pago completo",
        },
        Give48HoursNoticeToCancelOrReschedule: {
          key: "Give48HoursNoticeToCancelOrReschedule",
          value:
            "Para reprogramar, se requiere un aviso de 48 horas. Si reprogramas dentro de las 24 horas de tu cita, se te cobrará una tarifa de",
        },
        fee: {
          key: "fee",
          value: "por ",
        },
        SimplyCancelRebook: {
          key: "SimplyCancelRebook",
          value: "implemente cancela y reserva otra cita.",
        },
        noPreliminaryInformationCallIsNeededForBooking: {
          key: "noPreliminaryInformationCallIsNeededForBooking",
          value:
            "No se necesita una llamada de información preliminar para reservar. Para obtener ayuda, envíe un correo electrónico a",
        },
        contactSapflowAi: {
          key: "contactSapflowAi",
          value: "",
        },
      },
    },
    followUpAppointment: {
      content1: {
        key: "content1",
        value: `La evaluación de seguimiento SAP solo puede reservarse una vez completado el plan de tratamiento prescrito. Si reserva una evaluación de seguimiento con elementos del plan de tratamiento incompletos, se le cobrará una`,
      },
      content2: {
        key: "content2",
        value: `Si reprograma dentro de las 24 horas de su cita, se le cobrará una`,
      },
      content3: {
        key: "content3",
        value: `Si tiene un saldo pendiente en su cuenta, debe pagar su saldo restante al menos 2 horas antes de su Evaluación de Seguimiento.`,
      },
      content4: {
        key: "content4",
        value: `Si el saldo restante no se paga 2 horas antes de su cita, la cita se cancelará automáticamente y estará sujeta a`,
      },
      content5: {
        key: "content5",
        value: `Para reprogramar, cancele la cita e reserve otra cita.`,
      },
      title: {
        key: "title",
        value: "Cita de seguimiento",
      },
      lateRescheduleFee: {
        key: "lateRescheduleFee",
        value: "tarifa de reprogramación tardía.",
      }
    },
    appointmentBooking: {
      appointmentDate: {
        key: "appointmentDate",
        value: "Fecha de la cita",
      },
      appointmentTime: {
        key: "appointmentTime",
        value: "Hora de la cita",
      },
      duration: {
        key: "duration",
        value: "Duración",
      },
      minutes: {
        key: "minutes",
        value: "minutos",
      },
      appointmentDetails: {
        key: "appointmentDetails",
        value: "Detalles de la cita",
      },
      days: {
        Monday: {
          key: "Monday",
          value: "Lunes",
        },
        Tuesday: {
          key: "Tuesday",
          value: "Martes",
        },
        Wednesday: {
          key: "Wednesday",
          value: "Miércoles",
        },
        Thursday: {
          key: "Thursday",
          value: "Jueves",
        },
        Friday: {
          key: "Friday",
          value: "Viernes",
        },
        Saturday: {
          key: "Saturday",
          value: "Sábado",
        },
        Sunday: {
          key: "Sunday",
          value: "Domingo",
        },
      },
    },
    formFields: {
      firstName: {
        key: "firstName",
        value: "Nombre",
      },
      lastName: {
        key: "lastName",
        value: "Apellido",
      },
      email: {
        key: "email",
        value: "Correo electrónico",
      },
      mobileNumber: {
        key: "mobileNumber",
        value: "Número de móvil",
      },
      address: {
        key: "address",
        value: "Dirección",
      },
      dateOfBirth: {
        key: "dateOfBirth",
        value: "Fecha de nacimiento ",
      },
      last4DigitsOfSSN: {
        key: "last4DigitsOfSSN",
        value: "Últimos 4 dígitos de SSN",
      },
      bookandConfirm: {
        key: "bookandConfirm",
        value: "Reservar y confirmar",
      },
      cancel: {
        key: "cancel",
        value: "Cancelar",
      },
      male: {
        key: "male",
        value: "Masculino",
      },
      female: {
        key: "female",
        value: "Femenino",
      },
      other: {
        key: "others",
        value: "Otros",
      },
      gender: {
        key: "gender",
        value: "Género",
      },
      required_message: {
        key: "required_message",
        value: "La respuesta es obligatoria",
      },
      emptySpaces: {
        key: "emptySpaces",
        value: "No se permiten espacios vacíos",
      },
      nextSection: {
        key: "nextSection",
        value: "Siguiente sección",
      },
    },
    clientDashboard: {
      welcomeText: {
        key: "welcome_back",
        value: "Bienvenido de nuevo",
      },
      noAppointments: {
        key: "no_appointments",
        value: "No se encontraron citas",
      },
      currentStage: {
        key: "current_stage",
        value: "Etapa actual",
      },
      remainingEvidence: {
        key: "remaining_evidence",
        value: "Evidencias restantes",
      },
      outstandingBalance: {
        key: "outstanding_balance",
        value: "Saldo pendiente",
      },
      nextAppointmentDate: {
        key: "nextAppointmentDate",
        value: "Fecha de la próxima cita",
      },
    },
    clientAppointmentBooking: {
      notAvailableCurrently: {
        key: "notAvailableCurrently",
        value: "No disponible actualmente",
      },
      therapistUnavailableMessage: {
        key: "therapistUnavailableMessage",
        value:
          "El terapeuta que está intentando reservar no está disponible actualmente. Por favor, inténtelo de nuevo más tarde.",
      },
    },
  },
};

const TranslationKeys = {
  choose_your_appointent_time: "choose_your_appointent_time",
  login_text: "login_text",
  calendarOptions: {
    monthly: "monthly",
    weekly: "weekly",
    today: "today",
  },
  previous: "previous",
  next: "next",
  bookAppointment: "bookAppointment",
  reset: "reset",
  language: "language",
  navigation: {
    dashboard: "dashboard",
    appointmentList: "appointmentList",
    settings: "settings",
    appointmentBooking: "appointmentBooking",
    users: "users",
  },
  therapistDashboard: {
    appointmentsThisMonth: "appointmentsThisMonth",
    appointmentsLeftToday: "appointmentsLeftToday",
    clientsThisMonth: "clientsThisMonth",
    OutstandingBalance: "TotalOutstandingBalance",
    TodaysAppointments: "TodaysAppointments",
    NextAppointmentIn: "NextAppointmentIn",
    TotalEarnings: "TotalEarnings",
    TotalClients: "TotalClients",
    HaveANiceDayAtWork: "HaveANiceDayAtWork",
  },
  statuses: {
    pending: "pending",
    completed: "completed",
    onTime: "onTime",
    cancelled: "cancelled",
    followUp: "followUp",
    rescheduled: "rescheduled",
  },
  initialAssessement: {
    rules: {
      completeTheFollowingStepsBy8AmOnYouApptDay:
        "completeTheFollowingStepsBy8AmOnYouApptDay",
      beforeYourAppointment: "beforeYourAppointment",
      payANonRefundableDeposit: "payANonRefundableDeposit",
      depositHere: "depositHere",
      includeYourFirstAndLastNameInThePaymentNotes:
        "includeYourFirstAndLastNameInThePaymentNotes",
      register: "register",
      asYourSAPInTheClearinghouseIfApplicable:
        "asYourSAPInTheClearinghouseIfApplicable",
      fillOutTheNewClientFormAndAssessments:
        "fillOutTheNewClientFormAndAssessments",
      theseStepsMustBeCompletedToAvoidAutomaticRescheduling:
        "theseStepsMustBeCompletedToAvoidAutomaticRescheduling",
      minimumIsNeededToSecureYourBooking: "minimumIsNeededToSecureYourBooking",
      Give48HoursNoticeToCancelOrReschedule:
        "Give48HoursNoticeToCancelOrReschedule",
      fee: "fee",
      SimplyCancelRebook: "SimplyCancelRebook",
      noPreliminaryInformationCallIsNeededForBooking:
        "noPreliminaryInformationCallIsNeededForBooking",
      contactSapflowAi: "contactSapflowAi",
    },
  },
  followUpAppointment: {
    content1: "content1",
    content2: "content2",
    content3: "content3",
    content4: "content4",
    content5: "content5",
    title: "title",
    lateRescheduleFee: "lateRescheduleFee",
  },
  appointmentBooking: {
    appointmentDate: "appointmentDate",
    appointmentTime: "appointmentTime",
    duration: "duration",
    minutes: "minutes",
    appointmentDetails: "appointmentDetails",
    personalDetails: "personalDetails",
    days: {
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
    },
  },
  formFields: {
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    gender: "gender",
    mobileNumber: "mobileNumber",
    phone: "phone",
    address: "address",
    dateOfBirth: "dateOfBirth",
    last4DigitsOfSSN: "last4DigitsOfSSN",
    bookandConfirm: "bookandConfirm",
    cancel: "cancel",
    male: "male",
    female: "female",
    others: "others",
    required_message: "required_message",
    emptySpaces: "emptySpaces",
    nextSection: "nextSection",
  },
  clientDashboard: {
    welcomeText: "welcome_back",
    noAppointments: "no_appointments",
    currentStage: "current_stage",
    remainingEvidence: "remaining_evidence",
    outstandingBalance: "outstanding_balance",
    nextAppointmentDate: "nextAppointmentDate",
  },
  clientAppointmentBooking: {
    notAvailableCurrently: "notAvailableCurrently",
    therapistUnavailableMessage: "therapistUnavailableMessage",
  },
};

export { TRANSALATION_TEXT, TranslationKeys };
