import React from "react";
import { Tooltip } from "antd";
import { cashapp, paypal, stripe, venmo, zelle } from "components/imagepath";
import { TooltipMessages } from "./TooltipMessages";

const Default_Duration_In_Minutes = 60;

const DEFAULT_DISABLED_HOURS = [
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  0,
  ,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
];

const Languages = {
  EN: "en",
  ES: "es",
};

const Plan_Types = {
  starter: "Starter",
  professional: "Professional",
  enterprise: "Enterprise",
};

const PaymentTypes = ["Stripe", "PayPal", "Venmo", "CashApp"];

const PaymentIcons = {
  Stripe: stripe,
  Venmo: venmo,
  CashApp: cashapp,
  PayPal: paypal,
};

const SignUpTabs = {
  SignUp: 0,
  Subscription: 1,
  Payment: 2,
};

const Roles = {
  Therapist: "therapist",
  Client: "client",
  OfficeManager: "officeManager",
  Admin: "admin",
};

const TAB_KEYS = {
  assessmentInfo: "assessmentInfo",
  initialEvaluation: "initialEvaluation",
  treatment: "treatment",
  reports: "reports",
  evidenceSubmission: "evidenceSubmission",
  followupReport: "followupReport",
  followupEvaluation: "followupEvaluation",
};

const SETTINGS_TAB_KEYS = {
  profile: "personalInfo",
  appointmentSettings: "appointmentSettings",
  payment: "payment",
  questions: "questions",
  OfficeManager: "OfficeManager",
  Admin: "admin",
};

const QueryParamsString = {
  therapistId: "therapistId",
  clientId: "clientId",
  userId: "userId",
  appointmentId: "appointmentId",
  followUp: "followUp",
  reschedule: "reschedule",
  success: "success",
  error: "error",
  reportRequestId: "reportRequestId",
  cancelRescheduleAppointment: "cancelRescheduleAppointment",
};

const PaymentStatuses = {
  Success: "success",
  Error: "error",
  Process: "process",
  Pending: "pending",
  RequiresPayment: "requires_payment_method",
};

const DATE_VALIDATION_TYPES = {
  Greater: "greater",
  Lesser: "lesser",
  OnlyPast: "onlyPast",
  PastAndCurrentDay: "pastAndCurrentDay",
  OnlyFuture: "onlyFuture",
};

const ReportTypes = {
  initialEvaluation: "initial_evaluation_report",
  followupEvaluation: "follow_up_evaluation_report",
  followupReport: "followup_report",
  evidenceSubmission: "evidence_submission_report",
};

const AppointmentStatus = {
  Cancelled: "canceled",
  Completed: "completed",
  Scheduled: "scheduled",
};

const SubscriptionStatus = {
  active: "active",
  cancelled: "canceled",
  pending: "pending",
  suspended: "suspended",
};

const FileExtensions = {
  CSV: "csv",
  PDF: "pdf",
  XLSX: "xlsx",
};

const AppointmentQueries = {
  PendingPrequisities: "&initialAssessmentSubmitted=false",
  PendingReviewReport:
    "reportsPendingReview=true",
  DelayedPayments: "fullyPaid=false&treatmentPlanSubmitted=true",
  ReportRequests: "reportRequestOpen=true",
  PendingClearingHouse: "pendingClearingHouseAction=true",
};

const AssessmentTags = {
  undefined: (
    <div className="w-[5.5rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Pending
    </div>
  ),
  false: (
    <div className="w-[5.5rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Pending
    </div>
  ),
  true: (
    <div className="w-[7rem] text-sm font-medium text-secondary-dark flex items-center justify-center h-7 rounded-md bg-secondary-lighter">
      Completed
    </div>
  ),
};

const PaidTags = (paid, totalPaid, outstandingBalance, isFollowUp) => {
  if (isFollowUp && outstandingBalance > 0 && paid) {
    return (
      <div className="w-[5.3125rem] font-medium text-secondary-dark flex items-center justify-center h-7 rounded-md bg-yellow-200">
        Paid - ${totalPaid}
      </div>
    );
  } else if (paid) {
    return (
      <div className="w-[5.3125rem] font-medium text-secondary-dark flex items-center justify-center h-7 rounded-md bg-secondary-lighter">
        Paid - ${totalPaid}
      </div>
    );
  } else {
    return (
      <div className="w-[5.3125rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
        Not Paid
      </div>
    );
  }
};

const AppTypeTags = (followUp) => {
  if (followUp) {
    return (
      <div className="w-[5.3125rem] font-medium text-primary-dark flex items-center justify-center h-7 rounded-md ">
        Follow-Up
      </div>
    );
  } else {
    return (
      <div className="w-[5.3125rem] text-sm font-medium flex items-center justify-center h-7 rounded-md">
        Initial
      </div>
    );
  }
};

const RescheduleTags = {
  onTime: (
    <div className="w-[5.3125rem] text-sm font-medium text-secondary-dark flex items-center justify-center h-7 rounded-md bg-secondary-lighter">
      On-Time
      
    </div>
  ),
  rescheduled: (
    <div className="w-[6.325rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Rescheduled
    </div>
  ),
  canceled: (
    <div className="w-[5.3125rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Canceled
    </div>
  ),
};

const getScheduleTag = (record) => {
  if (record.status === "canceled") {
    return RescheduleTags.canceled;
  }
  if (record.status === "rescheduled") {
    return RescheduleTags.rescheduled;
  }
  return RescheduleTags.onTime;
};

const getAppointmentStage = (record) => {
  let currentStage = '-';
  
  if (!record.initialAssessmentSubmitted) { 
    currentStage = 'Intake Pending';
  } else if (record.initialAssessmentSubmitted && !record.initialEvaluationSubmitted) {
    currentStage = 'Initial Evaluation';
  } else if (record.initialEvaluationSubmitted && !record.treatmentPlanSubmitted) {
    return 'Treatment Plan';
  } else if (record.initialEvaluationSubmitted && !record.initialReportMailSent) {
    currentStage = 'Initial Report';
  } else if (record.initialReportMailSent && !record.followUpBooked) {
    currentStage = 'Under Treatment';
  } else if (record.followUpBooked) {
    currentStage = 'Follow Up';
  } else if (record.followUpEvaluationCompleted && !record.followUpReportMailSent) {
    currentStage = 'Follow Up Evaluation';
  } else if (record.followUpReportMailSent) {
    currentStage = 'Treatment Completed';
  }

  if (record.reportRequestOpen) {
    currentStage = 'Report Requested';
  } else {
    if (record.additionalReportRequests && record.additionalReportRequests.length > 0) {
      currentStage = 'Additional Report Sent';
    }
  }

  return currentStage;
};

const roleNames = {
  sap: "SAP",
  om: "Administrative Coordinator",
  admin: "Practice Manager",
};

const QuestionTemplates = {
  INITIAL_ASSESSMENT: "initialassessment",
  TREATMENT_PLAN: "treatmentplan",
  FOLLOWUP_EVALUATION: "followupevaluation",
  REPORT_REQUEST: "reportrequest",
  EVIDENCE_SUBMISSION: "evidencesubmission",
};

export {
  Default_Duration_In_Minutes,
  DEFAULT_DISABLED_HOURS,
  Languages,
  Plan_Types,
  PaymentTypes,
  PaymentIcons,
  SignUpTabs,
  Roles,
  TAB_KEYS,
  QueryParamsString,
  PaymentStatuses,
  SETTINGS_TAB_KEYS,
  DATE_VALIDATION_TYPES,
  ReportTypes,
  AppointmentStatus,
  FileExtensions,
  AppointmentQueries,
  SubscriptionStatus,
  AssessmentTags,
  PaidTags,
  AppTypeTags,
  RescheduleTags,
  getScheduleTag,
  getAppointmentStage,
  roleNames,
  QuestionTemplates,
};
