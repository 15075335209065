export const API_ROUTES = {
    "PATIENT": {
        "verifyUser": "/auth/verify-email",
        "bookAppointment": "/appointments/createAppointment",
        "getAssessmentQuestions": "/questions/initalAssessment",
        "submitQuestions": "/questions/saveInitialAssessmentResponse",
        "getAssessmentSavedResponse": "/questions/getInitialAssessmentBySection",
        "cancelAppointment": "appointments/cancel",
        "rescheduleAppointment": "appointments/reschedule",
        "getAdditionalReportQuestions": "questions/getAdditionalReportRequestQuestions",
        "submitAdditionalReportQuestions": "questions/saveAdditionalReportRequestQuestions",
        "updateAdditionalReportRequest": "appointments/updateReportRequest",
        "getDashboardDetails": "dashboard/client",
    },
    "DOCTOR": {
        "login": "",
        "getSubscriptions": "/subscriptions",
        "getUser": "/users",
        "submitInitialEvaluationAnswers": "questions/saveInitialEvaluationResponse",
        "submitTreatmentPlan": "questions/saveTreatmentPlanResponse",
        "getCalendarBookings": "/appointments/getAvailableSlotsForTherapist",
        "getAppointmentList": "/appointments/getAppointmentsList",
        "getAppointmentDetails": "/appointments/getAppointmentById",
        "updateAppointmentStatus": "appointments/udpateAppointment",
        "getInitialEvaluationPlan": "/questions/initalEvaluation",
        "getTreatmentPlan": "questions/getTreatmentPlan",
        "generateInitialReport": "reports/generateInitialReport",
        "getInitialReport": "reports/getInitialReport",
        "sendReportMail": "reports/sendReportMail",
        "dashboard": "dashboard/therapist",
        "getEvidenceSubmission": "questions/getEvidenceSubmission",
        "saveEvidence": "questions/saveEvidenceSubmissionSection ",
        "deleteEvidence": "questions/deleteEvidenceSubmissionSection",
        "getTherapistClientStats": "/dashboard/therapist/getClientStats",
        "getTherapistEarningStats": "/dashboard/therapist/getPaymentStats",
        "getFollowUpEvaluation": "questions/getFollowUpEvaluationResponse",
        "getFollowUpReport": "reports/getFollowUpReport",
        "saveFollowUpEvaluation": "questions/saveFollowUpEvaluationResponse",
        "exportAppointmentList": "appointments/export",
        "createOfficeManager": "settings/saveUserSettings",
        "createAdmin": "settings/saveUserSettings",
        "saveOutlookToken": "/auth/outlook-auth/save-token",
        "createCustomTokenForMicrosoftUsers": "/auth/createCustomTokenForMicrosoftUsers",
        "getQuestionTemplate": "settings/questionsTemplates",
        "saveQuestionTemplate": "settings/saveQuestionsTemplate",
    },
    ADMIN: {
        getClientStats: "dashboard/admin/getClientStats",
        getPaymentStats: "dashboard/admin/getPaymentStats",
        getDashboardDetails: "dashboard/admin",
        getUserList: "dashboard/admin/usersList"    
    },
    SETTINGS: {
        "getUserSettings": "settings/getUserSettings",
        "saveUserSettings": "settings/saveUserSettings",
    },
    REPORTS: {
        getClientAdditionalReport: "reports/refreshReport",
    },
    PAYMENT: {
        "getInvoice": "payments/getPaymentInvoice",
        "initiateStripePayment": "payments/stripe/initiatePayment",
        "getAppointementDetails": "payments/getPaymentDetailsForAppointment",
        "createAccount": "payments/stripe/createAccount",
        "createPaypalOrder": "/payments/paypal/createOrder",
        "capturePaypalOrder": "/payments/paypal/captureOrder",
        "createPaypalSubscription": "/users/paypal",
        "createStripeSubscription": "/users/stripe",
        "createPaypalAccount": "/payments/paypal/createAccount",
        "getStripeDashboardLink": "/payments/stripe/getDashboardLink",
        "getSubscriptionPageLink": "/payments/stripe/subscriptionDetails",
    },
    FEEDBACK:{
        "saveFeedback": '/appointments/saveFeedback',
    },
    NOTIFICATIONS: {
        "getNotifications": "/notifications/getNotifications",
        "markAsRead": "/notifications/markAsRead",
    }
}
