import { Route_Url } from "common/constants";
import { isEmpty } from "lodash";
import { dayjs } from './DateHelper'
import { translate } from "./TranslateHelper";

const SPLIT_SYMBOL = "<br/>";

const checkIfVerifiedUser = (userDetail) =>
  userDetail && userDetail?.emailVerified;

const range = (start, end) => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

const getUsername = (email) => {
  if (!email) return "";
  return email?.includes("@") ? email?.split("@")[0] : email;
};

const getInitials = (firstName, lastName) => {
  if (firstName?.length === 0 && lastName?.length === 0) return "";
  return firstName?.[0].toUpperCase() + lastName?.[0].toUpperCase();
};

const convertToTimeString = (time) => {
  const timeString = time.split(" to ")?.[0];
  return timeString;
}

const isValidData = (data) => !isEmpty(data);
const AddressRegex = /^(?:#?\d+)\s[A-Za-z0-9\s,.\-#]+(?:,\s[A-Za-z0-9\s]+)?(?:,\s[A-Za-z\s]+),\s[A-Za-z]{2,}\s\d{5}(-\d{4})?$/;
const isClient = (user) => user?.role?.toLowerCase() === "client";
const isTherapist = (user) => user?.role?.toLowerCase() === "therapist";
const isAdmin = (user) => user?.role?.toLowerCase() === "admin";
const isOfficeManager = (user) => user?.role?.toLowerCase() === "officemanager";
const isOfficeManagerOrTherapist = (user) => isOfficeManager(user) || isTherapist(user);
const hasActiveSubscription = (user) => user?.subscription?.subscriptionStatus === "active";
const isClientRoute = (pathName) => pathName?.includes(Route_Url.Client);
const isTherapistRoute = (pathName) => pathName?.includes(Route_Url.Therapist);
const isAdminRoute = (pathName) => pathName?.includes(Route_Url.Admin);
const isOfficeManagerRoute = (pathName) => pathName?.includes(Route_Url.OfficeManagerRoot);
const isAppointmentBookingRoute = (pathName) => pathName?.includes(Route_Url.AppointmentBooking);

const noEmptySpaces = (value) => value.trim() !== "" || "Empty spaces are not allowed";

const maxUsersCount = (user) => {
  //check if the user is a therapist, if yes get then subscription details
  const subscription = user?.subscription;
  const result = {};
  if (subscription) {
    const { roles } = subscription;
    if (roles) {
      const { sap, admin, om } = roles;
      result.maxSapCount = sap.maxUsers;
      result.maxAdminCount = admin.maxUsers;
      result.maxOMCount = om.maxUsers;
    }
  }
  return result;
}

 // Convert the file to Base64
 const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]); // Extract only the Base64 string
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

  const formatTextToJSXSplit = (text, splitText = SPLIT_SYMBOL) => {
    return text?.split(splitText);
  }


  const getGreeting = () => {
    const currentHour = dayjs().hour(); // Get the current hour (0-23)

    if (currentHour >= 5 && currentHour < 12) {
      return translate("morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return translate("afternoon");
    } else {
      return translate("evening");
    }
  };

  function camelCaseToSpacedCamelCase(str) {
    // Replace all uppercase letters with a space followed by the letter
    const spacedString = str.replace(/([A-Z])/g, ' $1');
    
    // Capitalize the first letter and return the result
    return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
  }
  

  const validateCommaSeparated = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    // Regex for a valid comma-separated list with possible spaces and special characters
    const pattern = /^[*,]+$/;  // Alphanumeric, spaces, parentheses, and commas
 
  
    // Check if each item in the list has valid characters
    if (!pattern.test(value)) {
      return Promise.reject('Please enter a valid list of items separated by commas');
    }
  
    return Promise.resolve();
  };
  

export {
  checkIfVerifiedUser,
  range,
  getUsername,
  isValidData,
  getInitials,
  isClient,
  isTherapist,
  isClientRoute,
  isTherapistRoute,
  isOfficeManagerRoute,
  hasActiveSubscription,
  maxUsersCount,
  noEmptySpaces,
  convertToTimeString,
  toBase64,
  isOfficeManager,
  isOfficeManagerOrTherapist,
  isAdmin,
  isAdminRoute,
  formatTextToJSXSplit,
  getGreeting,
  AddressRegex,
  camelCaseToSpacedCamelCase,
  validateCommaSeparated,
  isAppointmentBookingRoute
};
